import request from '@/utils/request'
export function getProposalData(data) {
  return request({
    url: '/proposal/proposal/getProposalData',
    method: 'post',
    data,
  })
}
// 投票
export function userVote(data) {
  return request({
    url: '/vote/vote/userVote',
    method: 'post',
    data,
  })
}
// 获取投票结果
export function voteResult(data) {
  return request({
    url: '/vote/vote/voteResult',
    method: 'post',
    data,
  })
}

// 投票详情列表
export function voteDetailList(data) {
  return request({
    url: '/vote/vote/voteDetails',
    method: 'post',
    data,
  })
}
// 不可参与地址
export function noVoteAccountList(data) {
  return request({
    url: '/vote/vote/noVoteAccountList',
    method: 'post',
    data,
  })
}

// 提交提案
export function CommitteeAddProposal(data) {
  return request({
    url: '/proposal/proposal/CommitteeAddProposal',
    method: 'post',
    data,
  })
}

// 委员会对于提案是否附议
export function SecondedResults(data) {
  return request({
    url: '/vote/vote/SecondedResults',
    method: 'post',
    data,
  })
}
// 委员会附议
export function CommitteeSecondedVote(data) {
  return request({
    url: '/vote/vote/CommitteeSecondedVote',
    method: 'post',
    data,
  })
}
// 委员会投票
export function committeeVote(data) {
  return request({
    url: '/vote/vote/committeeVote',
    method: 'post',
    data,
  })
}
// 撤销附议
export function revokeProposal(data) {
  return request({
    url: '/proposal/proposal/revokeProposal',
    method: 'post',
    data,
  })
}
